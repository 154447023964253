import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCalendarDay, faDolly, faHashtag, faMapMarkerAlt, faTag} from '@fortawesome/free-solid-svg-icons';

const BaggingReportSummary = (props) => {
  let { canApprove, contractNumber, tables, dateString, locations, onApproveBaggingReports, isApproving } = props;
  return (
    <div className={`panel has-background-white ${canApprove ? '' : 'is-success'}`}>
      <div className="panel-heading has-text-centered">{contractNumber}</div>
      <div className="panel-block">
        <div className="w-100">
          <article className="media">
            <figure className="media-left">
              <FontAwesomeIcon icon={faHashtag} fixedWidth />
            </figure>
            <div className="media-content">
              <div className="content">
                <p><strong> Bagging Report No.</strong><br/>
                  {tables.map(tbl => tbl.reportId).join(' | ')}</p>
              </div>
            </div>
          </article>
          <article className="media">
            <figure className="media-left">
              <FontAwesomeIcon icon={faCalendarDay} fixedWidth />
            </figure>
            <div className="media-content">
              <div className="content">
                <p><strong> Date</strong> <br/>
                  {dateString}</p>
              </div>
            </div>
          </article>
          <article className="media">
            <figure className="media-left">
              <FontAwesomeIcon icon={faTag} fixedWidth />
            </figure>
            <div className="media-content">
              <div className="content">
                <p><strong> Source</strong> <br/>
                  {tables.length ? tables[0].reports[0].seedbin_data_source : ''}</p>
              </div>
            </div>
          </article>
          <article className="media">
            <figure className="media-left"><FontAwesomeIcon icon={faDolly} fixedWidth /></figure>
            <div className="media-content">
              <div className="content">
                <p><strong> Activity</strong> <br/>
                  Bagging</p>
              </div>
            </div>
          </article>
          <article className="media">
            <figure className="media-left"><FontAwesomeIcon icon={faMapMarkerAlt} fixedWidth /></figure>
            <div className="media-content">
              <div className="content">
                <p><strong> Location</strong> <br/>
                  {locations}</p>
              </div>
            </div>
          </article>
          {/* <article className="media">
            <figure className="media-left"><FontAwesomeIcon icon={faTools} fixedWidth /></figure>
            <div className="media-content">
              <div className="content">
                <p><strong> Supplies Used</strong> <br/>
                  Supplies</p>
              </div>
            </div>
          </article> */}
        </div>
      </div>
      <div className="panel-block has-text-centered">
        {canApprove ?
          <button onClick={onApproveBaggingReports}
                  className={`button is-primary is-fullwidth ${isApproving ? 'is-loading' : ''}`}>
            Approve All
          </button> :
          <span className={'tag is-success is-light is-size-5 mx-auto'}>APPROVED</span>
        }
      </div>
    </div>
  );
};

export default BaggingReportSummary;

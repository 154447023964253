import React from "react";
import {
  faMapMarkerAlt,
  faSeedling,
  faCalendarDay,
  faChevronRight,
  faTag,
  faHashtag,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import {
  getBaggingReportLocations,
  getBaggingReportVarieties,
  getBaggingReportDates,
} from "../../utils/array";

const Contract = ({ number, reports = [], crop, source }) => {
  let locations = getBaggingReportLocations(reports);
  let totalLineItems = reports.length;
  let varieties = getBaggingReportVarieties(reports);
  let dateString = getBaggingReportDates(reports);
  let needsApproval = reports.filter((report) => !report.downloadable).length;

  let total = 0;
  reports.forEach((report) => {
    total += report.quantity_to_ship;
  });

  return (
    <div className="box has-background-white">
      <div className="columns is-mobile">
        <div className="column is-5">
          <h4 className="title is-4 has-text-primary has-text-weight-bold is-marginless">
            {number}
            &nbsp;
            <small className="has-text-weight-normal is-size-6 has-text-grey">
              {varieties.join(", ")}
            </small>
          </h4>
          <div className="has-text-grey mt-10 is-size-6">
            <div className="inline-block mr-15">
              <FontAwesomeIcon icon={faMapMarkerAlt} />
              &nbsp;
              <span>{locations.join(", ")}</span>
            </div>

            <div className="inline-block">
              <FontAwesomeIcon icon={faSeedling} />
              &nbsp;
              <span>{crop}</span>
            </div>
          </div>
        </div>
        <div className="column">
          <div className="has-text-weight-bold">
            {totalLineItems} Line Items
            <span className="tag is-warning is-light has-text-weight-normal">
              {needsApproval} unapproved
            </span>
            <span className="tag is-success is-light has-text-weight-normal">
              {totalLineItems - needsApproval} approved
            </span>
          </div>
          <div className="has-text-grey mt-10 is-size-6">
            <div className="inline-block mr-15">
              <FontAwesomeIcon icon={faCalendarDay} />
              &nbsp;
              <span>{dateString}</span>
            </div>
            <div className="inline-block mr-15">
              <FontAwesomeIcon icon={faTag} />
              &nbsp;
              <span>{source}</span>
            </div>
            <div className="block">
              <FontAwesomeIcon icon={faHashtag} />
              Total 80M Units: &nbsp;
              <span>{total}</span>
            </div>
          </div>
        </div>
        <div className="column is-flex items-center justify-end is-size-5 has-text-grey">
          <Link
            className={"has-text-grey"}
            to={{
              pathname: "/contract/" + number,
              state: {
                reports,
              },
            }}
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Contract;

import React, {Component} from 'react';

class Td extends Component {
  constructor(props) {
    super(props);
    
    this.handleClick = this.handleClick.bind(this);
  }
  
  handleClick() {
    if (this.props.onClick)
      this.props.onClick({
        row: this.props.row, 
        td: this.props.val
      });
  }
  
  render() {
    return (
      <td onClick={this.handleClick}>{this.props.val}</td>
    );
  }
}

export default Td;

import React, { useState, useEffect } from "react";
import * as Sentry from "@sentry/browser";
import fetchBaggingReports, { downloadCSV } from "../../utils/api";
import { useAuth0 } from "../../auth0-spa";
import Spinner from "../spinner";
import Contract from "../contract";
import NoData from "../no-data";
import SearchInput from "../search-input";
import DatePicker from "react-date-picker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSortAmountDown,
  faSortAmountUp,
} from "@fortawesome/free-solid-svg-icons";
import download from "downloadjs";

const Contracts = (props) => {
  let { loading, getTokenSilently, user } = useAuth0();
  let [isFetching, setFetching] = useState(false);
  let [reports, setReports] = useState([]);
  let [contracts, setContracts] = useState([]);
  let [filter, setFilter] = useState("");
  let [searchValue, setSearchValue] = useState("");
  let [sort, setSort] = useState("desc");
  let [startDate, setStartDate] = useState(
    new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() - 7
    )
  );
  let [endDate, setEndDate] = useState(null);
  let [showUnapproved, setShowUnapproved] = useState(true);
  let [showApproved, setShowApproved] = useState(true);
  let { selectedCompany } = props;

  useEffect(() => {
    setContracts(
      reports
        .filter((report) => {
          if (!filter) return true;
          return new RegExp(filter, "i").test(report.contract_number);
        })
        .sort((a, b) => {
          if (sort === "asc") {
            return `${a.report_date || ""}`.localeCompare(
              `${b.report_date || ""}`
            );
          }

          return `${b.report_date || ""}`.localeCompare(
            `${a.report_date || ""}`
          );
        })
        .reduce((p, c) => {
          if (!p.includes(c.contract_number)) p.push(c.contract_number);
          return p;
        }, [])
        .map((contract) => {
          return {
            contract,
            reports: reports.filter(
              (report) => report.contract_number === contract
            ),
          };
        })
        .filter((contract) => {
          if (showUnapproved && showApproved) return true;

          if (!showUnapproved && !showApproved) return false;

          let needsApproval = contract.reports.filter(
            (report) => !report.downloadable
          ).length;

          let alreadyApproved = contract.reports.filter(
            (report) => report.downloadable
          ).length;

          if (showUnapproved) return needsApproval;
          if (showApproved) return alreadyApproved;

          return !needsApproval;
        })
    );
  }, [reports, filter, sort, showUnapproved, showApproved]);

  useEffect(() => {
    const getBaggingReports = async () => {
      setFetching(true);
      let token = await getTokenSilently();
      let fromFake = false;

      try {
        let res = await fetchBaggingReports({
          searchValue: searchValue,
          filterBy: "",
          sortBy: "",
          token,
          fromFake,
          email: user.email,
          companyId: selectedCompany,
          startDate,
          endDate,
        });
        setReports(res.data);
      } catch (e) {
        let { response = {} } = e;
        let { status } = response;

        alert(e.message);

        if (status && status >= 500) {
          console.error(e.message);
          Sentry.captureException(e);
        }
      }
    };

    if (!loading) {
      getBaggingReports().finally(() => setFetching(false));
    }
  }, [
    loading,
    getTokenSilently,
    user,
    startDate,
    endDate,
    selectedCompany,
    searchValue,
  ]);

  const searchBaggingReports = (e) => {
    setTimeout(() => {
      setSearchValue(e.target.value);
    }, 800);
  };

  const downloadFile = async (props) => {
    let token = await getTokenSilently();
    let fromFake = false;
    let res = await downloadCSV({
      contractnumber: filter,
      sortBy: "",
      token,
      fromFake,
      email: user.email,
      companyId: "",
      startDate,
      endDate,
      selectedCompany,
      showApproved,
      showUnapproved,
      searchValue,
    });

    let fileBody = await res.data;
    let fileNameHeader = res.headers["content-disposition"];
    let startFileNameIndex = fileNameHeader.indexOf('"') + 1;
    let endFileNameIndex = fileNameHeader.lastIndexOf('"');
    let fileName = fileNameHeader.substring(
      startFileNameIndex,
      endFileNameIndex
    );

    download(fileBody, fileName);
  };

  return (
    <div>
      <div className="columns is-vcentered sticky-top1">
        <div className="column">
          <h2 className="title is-2">Contracts</h2>
        </div>
        <div className="column has-text-right">
          <button onClick={(e) => downloadFile(e)} className="button">
            Download CSV
          </button>
        </div>
      </div>

      <div className="columns is-vcentered sticky-top2">
        <div className="column">
          <div className="box">
            <label className="label">Status</label>

            <div className="field is-grouped min-h-input">
              <div className="control align-self-end">
                <label className="checkbox">
                  <input
                    onChange={(e) => setShowApproved(e.target.checked)}
                    defaultChecked={showApproved}
                    type="checkbox"
                  />{" "}
                  Approved
                </label>
              </div>

              <div className="control align-self-end">
                <label className="checkbox">
                  <input
                    onChange={(e) => setShowUnapproved(e.target.checked)}
                    defaultChecked={showUnapproved}
                    type="checkbox"
                  />{" "}
                  Unapproved
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="column">
          <div className="box">
            <label className="label">Sort, Start & End Dates</label>
            <div className="field is-grouped">
              <div className="control has-icons-left">
                <span className="icon is-left" style={{ zIndex: 5 }}>
                  <FontAwesomeIcon
                    icon={sort === "asc" ? faSortAmountUp : faSortAmountDown}
                  />
                </span>

                <div className="select is-success">
                  <select
                    title="Sort By Report Date"
                    onChange={(e) => setSort(e.target.value)}
                    value={sort}
                  >
                    <option value="asc">Asc.</option>
                    <option value="desc">Desc.</option>
                  </select>
                </div>
              </div>

              <div className="control">
                <DatePicker
                  value={startDate}
                  onChange={(e) => setStartDate(e)}
                />
              </div>

              <div className="control">
                <DatePicker value={endDate} onChange={(e) => setEndDate(e)} />
              </div>
            </div>
          </div>
        </div>

        <div className="column">
          <div className="box">
            <label className="label">Search</label>
            <SearchInput onSearch={searchBaggingReports} />
          </div>
        </div>
      </div>

      {(loading || isFetching) && <Spinner />}

      {!!contracts.length &&
        !loading &&
        !isFetching &&
        contracts.map((contract) => (
          <Contract
            key={`contract-item-${contract.contract}`}
            number={contract.contract}
            reports={contract.reports}
            crop="Corn"
            source={contract.reports[0].seedbin_data_source}
          />
        ))}

      {!contracts.length && !loading && !isFetching && (
        <div className="box">
          <NoData
            message={
              "No contracts found. Try broadening the filter criteria above."
            }
          />
        </div>
      )}
    </div>
  );
};

export default Contracts;

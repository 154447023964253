import React, {Component} from 'react';
import baggingReportsTableSettings from "../../utils/baggingReportsTableSettings";

class BaggingReportFields extends Component {
  render() {
    return (
      <div className="columns is-multiline">
        {this.getReportKeys().map((field, i) => {
          let val = this.getReportValue(field);
          let label = this.getFieldLabel(field);

          return (<div key={`report-detail-${i}`} className="column is-3">
            <div className="field">
              <label className="label is-small">{label}</label>
              <p className="control">
                {/*placeholder={label} */}
                <input className="input is-success"
                       disabled
                       type="text"
                       value={val || ''} />
              </p>
            </div>
          </div>)
        })}
      </div>
    );
  }

  getReportKeys() {
    let exclude = [
      'id',
      'bagging_report_id',
      'related_record',
      'related_production_update',
      'activity',
      'display',
      'migratedQB'
    ];
    return Object
      .keys(this.props.baggingReport)
      .filter(key => !exclude.includes(key));
  }

  getReportValue(field) {
    let { baggingReport } = this.props;
    let { formats } = baggingReportsTableSettings;
    let formatter = formats[field];

    return formatter ? formatter(baggingReport[field]) : baggingReport[field];
  }

  getFieldLabel(field) {
    let { labels } = baggingReportsTableSettings;

    return labels[field] || field;
  }
}

export default BaggingReportFields;

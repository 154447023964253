const userTableSettings = {
  headers: [
    {
      colName: "first_name",
      title: "First Name",
    },
    {
      colName: "last_name",
      title: "Last Name",
    },
    {
      colName: "username",
      title: "Username",
    },
    {
      colName: "last_login",
      title: "Last Access",
    },
  ],
  formats: {
    last_login: function(dateString) {
      if (dateString) {
        let date = new Date(dateString);
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();

        if (day < 10) {
          day = "0" + day;
        }
        if (month < 10) {
          month = "0" + month;
        }
        return month + "/" + day + "/" + year;
      } else {
        return null;
      }
    },
  },
  labels: {
    first_name: "First Name",
    last_name: "Last Name",
    username: "Username",
    last_login: "Last Access",
  },
  types: {
    first_name: String,
    last_name: String,
    username: String,
    last_login: Date,
  },
};

export default userTableSettings;

import axios from "axios";
import { urlBase } from "../env.config";
import genFakeData from "./genFakeData";

export const fetchBaggingReports = async (config) => {
  let endpoint = `${urlBase}/api/baggingreports/corn/`;
  let {
    filterBy,
    sortBy,
    token,
    fromFake,
    startDate,
    endDate,
    companyId,
    contract_number,
    searchValue,
  } = config;
  let fakeData = await genFakeData(0, true);

  if (fromFake) {
    return Promise.resolve({ data: fakeData });
  }

  let params = { format: "json" };

  if (contract_number) {
    params.contract_number = contract_number;
    return makeAxiosCall({ endpoint, params, token });
  }

  if (filterBy) params.filterBy = filterBy;
  if (searchValue) params.search = searchValue;
  if (sortBy) params.sortBy = sortBy;
  if (companyId) params.company = companyId;
  if (startDate && startDate instanceof Date)
    params.startdate = startDate.toLocaleDateString("en-CA");
  if (endDate && endDate instanceof Date)
    params.enddate = endDate.toLocaleDateString("en-CA");

  return makeAxiosCall({ endpoint, params, token });

  function makeAxiosCall({ endpoint, params, token }) {
    return axios.get(endpoint, {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
};

export const fetchBaggingReport = async (config) => {
  let { token, id, fromFake, signal } = config;

  if (fromFake) {
    let fakeData = await genFakeData(0, true);
    return {
      data: fakeData.find((record) => record.id === id),
    };
  }

  return await axios.get(`${urlBase}/api/baggingreports/corn/${id}/`, {
    signal,
    params: {
      format: "json",
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const editBaggingReport = async (config) => {
  let { token, recordId, fromFake, edits /*, email, companyId*/ } = config;

  if (fromFake) {
    let fakeData = await genFakeData(0, true);
    let record = fakeData.find((record) => record.id === recordId) || {};

    for (let field in edits) {
      record[field] = edits[field];
    }

    return Promise.resolve({ data: record });
  }

  return axios.patch(`${urlBase}/api/baggingreports/corn/${recordId}`, edits, {
    params: {
      format: "json",
    },
    headers: {
      Authorization: `Bearer ${token}`,
      //EMAIL: email,
      //COMPANYID: companyId
    },
  });
};

export const editBaggingReports = async (config) => {
  let { token, reports, fromFake, edits /*, email, companyId*/ } = config;

  if (fromFake) {
    return Promise.all(
      reports.map((report) => {
        return editBaggingReport({
          token,
          recordId: report.id,
          fromFake,
          edits,
          //email,
          //companyId
        });
      })
    );
  }

  return axios.all(
    reports.map((report) => {
      return editBaggingReport({
        token,
        recordId: report.id,
        fromFake,
        edits,
        //email,
        //companyId
      });
    })
  );
};

export const fetchUsers = async (config) => {
  let { token } = config;

  return await axios.get(`${urlBase}/api/admin/users`, {
    params: {
      format: "json",
      limit: "1000",
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const fetchUserDetails = async (config) => {
  let { token, pkid } = config;

  return await axios.get(`${urlBase}/api/admin/users/${pkid}`, {
    params: {
      format: "json",
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const downloadCSV = async (config) => {
  let {
    contractnumber,
    filterBy,
    sortBy,
    token,
    fromFake,
    startDate,
    endDate,
    selectedCompany,
    showApproved,
    showUnapproved,
    searchValue,
  } = config;
  let fakeData = await genFakeData(0, true);

  let params = { format: "csv" };

  if (filterBy) params.filterBy = filterBy;
  if (contractnumber) params.contract_number = contractnumber;
  if (sortBy) params.sortBy = sortBy;
  if (startDate && startDate instanceof Date)
    params.startdate = startDate.toLocaleDateString("en-CA");
  if (endDate && endDate instanceof Date)
    params.enddate = endDate.toLocaleDateString("en-CA");
  if (selectedCompany) params.company = selectedCompany;
  if (showApproved && !showUnapproved) params.downloadable = true;
  if (!showApproved && showUnapproved) params.downloadable = false;
  if (searchValue) params.search = searchValue;

  if (fromFake) {
    return Promise.resolve({ data: fakeData });
  }

  return axios.get(`${urlBase}/api/baggingreports/corn/`, {
    params,
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const fetchUserCompanies = async (config) => {
  let { token } = config;

  return await axios.get(`${urlBase}/api/seedbinuser/`, {
    params: {
      format: "json",
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export default fetchBaggingReports;

import React from 'react';
import { useAuth0 } from '../../auth0-spa';
import Spinner from '../spinner';
import { Redirect } from 'react-router-dom';

const LogUserIn = () => {
  let { loading, isAuthenticated, loginWithRedirect } = useAuth0();

  if (loading) {
    return <Spinner/>;
  }

  if (isAuthenticated) {
    return <Redirect to={'/'}/>
  }
      
  return loginWithRedirect();
};

export default LogUserIn;

import React, { Component } from "react";
import userTableSettings from "../../utils/userTableSettings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDay,
  faMapMarkerAlt,
  faUser,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";

const UserDetails = (props) => {
  let data = props.history.location.state.data.row;
  let firstName = data.first_name;
  let lastName = data.last_name;
  let companies = data.CompaniesArr;
  let username = data.username;
  let lastLogin = data.last_login;
  let formattedLastLogin = "";
  if (lastLogin) {
    let date = new Date(lastLogin);
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();

    if (day < 10) {
      day = "0" + day;
    }
    if (month < 10) {
      month = "0" + month;
    }
    formattedLastLogin = month + "/" + day + "/" + year;
  }

  const lastAccessStyle = {
    display: "inline",
    float: "right",
    textAlign: "right",
    marginBottom: "0em",
  };

  let companyArr = [];
  companies.forEach((company) => {
    companyArr.push(company.company_name);
  });
  companyArr.sort();
  let pkid = data.pkid;
  return (
    <div className="panel has-background-white">
      <div className="panel-heading has-text-centered">
        {/* <FontAwesomeIcon icon={faUser} fixedWidth /> */}
      </div>
      <div className="panel-block">
        <div className="w-100">
          <article className="media">
            <figure className="media-left"></figure>
            <div className="media-content">
              <div className="content">
                <p>
                  <strong>User Details</strong>
                  <p style={lastAccessStyle}>
                    <FontAwesomeIcon icon={faCalendarDay} fixedWidth />
                    <strong>Last Access:</strong> {formattedLastLogin}
                  </p>
                  <br />
                </p>
              </div>
            </div>
          </article>

          <article className="media">
            <figure className="media-left">
              <FontAwesomeIcon icon={faUser} fixedWidth />
            </figure>
            <div className="media-content">
              <div className="content">
                <p>
                  <strong> Full Name</strong> <br />
                  {firstName} {lastName}
                </p>
              </div>
            </div>
          </article>
          <article className="media">
            <figure className="media-left">
              <FontAwesomeIcon icon={faEnvelope} fixedWidth />
            </figure>
            <div className="media-content">
              <div className="content">
                <p>
                  <strong> Username</strong> <br />
                  {username}
                </p>
              </div>
            </div>
          </article>
          <article className="media">
            <figure className="media-left">
              <FontAwesomeIcon icon={faMapMarkerAlt} fixedWidth />
            </figure>
            <div className="media-content">
              <div className="content">
                <strong> Companies</strong> <br />
                {companyArr.map((company, i) => (
                  <ul>
                    <li
                      key={`company-${company}`}
                      className={"tag is-secondary is-light is-size-8 mx-auto"}
                    >
                      {company}
                    </li>
                  </ul>
                ))}
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>
  );
};

export default UserDetails;

import React  from 'react';
import UserDropDown from './user-drop-down';

const Nav = ({userName, userProfile, logout, children, title, logo, bigLogo}) => {
  
  return (<nav className="navbar is-dark is-right sticky-top" role="navigation" aria-label="main navigation">
        <div className="container is-fluid">
          <div className="navbar-brand">
            <span className={`navbar-item ${bigLogo ? 'p-0' : ''}`}>
              <img className={bigLogo ? 'max-h-3rem' : ''} src={logo} alt="MayerSeedline" />
            </span>
            <span className="navbar-item">{title}</span>
          </div>
          <div className="navbar-menu">
            <div className="navbar-end">  
              <div className="navbar-item is-paddingless">
                <UserDropDown userName={userName} userProfile={userProfile} logout={logout}>
                  {children}
                </UserDropDown>
              </div>
            </div>
          </div>
        </div>
      </nav>
    );
};

export default Nav;

import React from 'react';
import { NavLink } from 'react-router-dom';

const AppMenu = (props) => {
  var showAdmin = 
    props.user.email.includes('mayerseedline') 
    || props.user.email.includes('seedbin');

  return (
      <aside className={`menu pt-3 pr-half`}>
        <p className="menu-label">Contracts</p>
        <ul className="menu-list">
          <li><NavLink to={'/'} exact activeClassName={'is-active'}>Bagging Reports</NavLink></li>
        </ul>
        <div>
          {
            showAdmin ? 
              <p className="menu-label">Administration</p> 
            : ''
          }
          {
            showAdmin ? 
              <ul className="menu-list">
                <li><NavLink to={'/users'} exact activeClassName={'is-active'}>Users</NavLink></li>
              </ul> 
            : ''
          }
        </div>
        {/* <p className="menu-label">Other</p>
        <ul className="menu-list">
          <li><NavLink to="/help" activeClassName={'is-active'}>Help</NavLink></li>
          <li><NavLink to="/contact" activeClassName={'is-active'}>Contact</NavLink></li>
        </ul> */}
      </aside>
  );
};

export default AppMenu;

const baggingReportsTableSettings = {
  headers: [
    {
      colName: "variety",
      title: "Product Name",
    },
    {
      colName: "customer_seed_size",
      title: "Size",
    },
    {
      colName: "batch_id",
      title: "Lot #",
    },
    {
      colName: "treatment",
      title: "Treatment",
    },
    {
      colName: "ssu_package_weight",
      title: "Bag Weight",
    },
    {
      colName: "quantity_to_ship",
      title: "Units",
    },
    {
      colName: "package_type",
      title: "Package",
    },
  ],
  formats: {
    bagging_report_date: function(dateString) {
      return new Date(dateString).toLocaleDateString();
    },
    // refuge_test_date: function(dateString) {
    //   if (dateString) {
    //     return new Date(dateString).toLocaleDateString();
    //   } else {
    //     return null;
    //   }
    // },
    // test_date: function (dateString) {
    //   if (dateString) {
    //     return new Date(dateString).toLocaleDateString();
    //   } else {
    //     return null;
    //   }
    // },
    downloadable: function(isDownloadable) {
      return isDownloadable ? "\u2713" : "";
    },
  },
  labels: {
    id: "ID",
    downloadable: "Approved",
    customer_identifier: "Cust ID",
    report_id: "Report ID",
    report_date: "Date",
    report_type: "Type",
    report_location_code: "Location",
    report_notes: "Notes",
    species: "Species",
    crop_year: "Crop Year",
    contract_number: "Contract #",
    related_production_update: "Production Update",
    customer_reference_id: "Cust Ref ID",
    ssu_package_weight: "Bag Weight",
    number_of_packages: "# of Pkgs",
    screen_size: "Screen Size",
    plate_size: "Plate Size",
    customer_seed_size: "Cust Seed Size",
    batch_id: "Lot #",
    seeds_per_package: "Seeds/Pkg",
    seeds_per_lb: "Seeds/Lbs",
    quantity_to_ship: "Qty",
    shipped_uom: "Shipped UOM",
    unit_weight: "Unit Weight",
    package_weight_lb: "Pkg Weight (KGs)",
    relative_maturity: "Rel Maturity",
    test_date: "Test Date",
    origin: "Origin",
    finished_type: "Finished Type",
    label_germ: "Label Germ",
    tag_code: "Tag Code",
    bag_lip_code: "Bag Lip Code",
    treatment: "Treatment",
    brand_alias: "Brand Alias",
    package_type: "Pkg Type",
    package_description: "Pkg Desc.",
    colorant: "Colorant",
    product_code: "Prod Code",
    refuge_origin: "Ref Origin",
    refuge_label_germ: "Ref Label Germ",
    refuge_test_date: "Ref Test Date",
    refuge_product_code: "Ref Prod Code",
    refuge_trait: "Ref Trait",
    refuge_batch_id: "Ref Lot #",
    refuge_seeds_per_lb: "Ref Seeds/Lbs",
    refuge_tag_code: "Ref Tag Code",
    refuge_treatment: "Ref Treatment",
    refuge_colorant: "Ref Colorant",
  },
  types: {
    id: Number,
    downloadable: Boolean,
    customer_identifier: String,
    report_id: String,
    report_date: Date,
    report_type: String,
    report_location_code: String,
    report_notes: String,
    species: String,
    crop_year: String,
    contract_number: String,
    related_production_update: String,
    customer_reference_id: String,
    ssu_package_weight: Number,
    number_of_packages: String,
    screen_size: String,
    plate_size: String,
    customer_seed_size: String,
    batch_id: String,
    seeds_per_package: Number,
    seeds_per_lb: Number,
    quantity_to_ship: Number,
    shipped_uom: String,
    unit_weight: String,
    package_weight_lb: Number,
    relative_maturity: String,
    test_date: Date,
    origin: String,
    finished_type: String,
    label_germ: Number,
    tag_code: String,
    bag_lip_code: String,
    treatment: String,
    brand_alias: String,
    package_type: String,
    package_description: String,
    colorant: String,
    product_code: String,
    refuge_origin: String,
    refuge_label_germ: Number,
    refuge_test_date: Date,
    refuge_product_code: String,
    refuge_trait: String,
    refuge_batch_id: String,
    refuge_seeds_per_lb: Number,
    refuge_tag_code: String,
    refuge_treatment: String,
    refuge_colorant: String,
  },
};

export default baggingReportsTableSettings;

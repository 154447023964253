import baggingReportsTableSettings from "./baggingReportsTableSettings";
import userTableSettings from "./userTableSettings";

export const groupBaggingReportsById = (reports) => {
  return reports
    .reduce((p, c) => {
      if (!p.includes(c.report_id)) p.push(c.report_id);
      return p;
    }, [])
    .map((reportId) => {
      return {
        reportId,
        reports: reports.filter((report) => report.report_id === reportId),
      };
    });
};

export const getBaggingReportLocations = (reports) => {
  let locationCodes = reports
    .reduce((p, c) => {
      if (!p.includes(c.report_location_code)) p.push(c.location_location_code);
      return p;
    }, [])
    .filter((code) => code);

  if (locationCodes.length) {
    return locationCodes;
  }

  return reports
    .reduce((p, c) => {
      if (!p.includes(c.origin)) p.push(c.origin);
      return p;
    }, [])
    .filter((origin) => origin);
};

export const getBaggingReportCrops = (reports) => {
  return reports.reduce((p, c) => {
    if (!p.includes(c.crop)) p.push(c.crop);
    return p;
  }, []);
};

export const getBaggingReportVarieties = (reports) => {
  return reports.reduce((p, c) => {
    if (!p.includes(c.product_name)) p.push(c.product_name);
    return p;
  }, []);
};

export const getSortedBaggingReportsDates = (reports) => {
  return reports
    .map((report) => report.report_date)
    .filter((report) => report)
    .sort((a, b) => {
      return new Date(a).getTime() - new Date(b).getTime();
    });
};

export const getBaggingReportDates = (reports) => {
  let dates = getSortedBaggingReportsDates(reports);

  if (!dates.length) {
    return "None";
  }

  let startDate = new Date(dates[0]).toLocaleDateString();
  let endDate = new Date(dates[dates.length - 1]).toLocaleDateString();
  return startDate === endDate ? startDate : startDate + " - " + endDate;
};

export const filter = (config) => {
  let { row, date_1, date_2, filterBy } = config;
  let rowDate = new Date(row["report_date"] || null);
  let withinDates = true;

  // date_1 & date_2 can be null.
  if (date_1 && date_2) {
    withinDates =
      rowDate.getTime() >= date_1.getTime() &&
      rowDate.getTime() <= date_2.getTime();
  }

  if (!filterBy) return withinDates;

  let search = new RegExp(filterBy, "i");
  let searchableData = Object.values(row);

  return searchableData.some((td) => search.test(td)) && withinDates;
};

export const sort = (config) => {
  let { a, b, sortBy, sortDirection, table } = config;
  let isAsc = sortDirection === "asc";
  let model =
    table === "users"
      ? userTableSettings.types
      : baggingReportsTableSettings.types;

  if (!sortBy || !model[sortBy]) return 0;

  if (model[sortBy] === Number) {
    return isAsc ? a[sortBy] - b[sortBy] : b[sortBy] - a[sortBy];
  }

  if (model[sortBy] === String) {
    return isAsc
      ? a[sortBy].localeCompare(b[sortBy])
      : b[sortBy].localeCompare(a[sortBy]);
  }

  let distantFuture = new Date(8640000000000000);
  let distantFutureFormat =
    distantFuture.getMonth() +
    "/" +
    distantFuture.getDate() +
    "/" +
    distantFuture.getFullYear();

  if (sortBy == "last_login" && model[sortBy] === Date) {
    let firstDate =
      new Date(a[sortBy]).getMonth() +
      "/" +
      new Date(a[sortBy]).getDate() +
      "/" +
      new Date(a[sortBy]).getYear();

    let secondDate =
      new Date(b[sortBy]).getMonth() +
      "/" +
      new Date(b[sortBy]).getDate() +
      "/" +
      new Date(b[sortBy]).getYear();

    let [month, day, year] = firstDate
      ? firstDate.split("/")
      : distantFutureFormat.split("/");
    let [bMonth, bDay, bYear] = secondDate
      ? secondDate.split("/")
      : distantFutureFormat.split("/");
    let aDate = new Date(year, month, day);
    let bDate = new Date(bYear, bMonth, bDay);

    return isAsc
      ? aDate.getTime() - bDate.getTime()
      : bDate.getTime() - aDate.getTime();
  }
  if (model[sortBy] === Date) {
    let [month, day, year] = a[sortBy]
      ? a[sortBy].split("/")
      : distantFutureFormat.split("/");
    let [bMonth, bDay, bYear] = b[sortBy]
      ? b[sortBy].split("/")
      : distantFutureFormat.split("/");
    let aDate = new Date(year, month, day);
    let bDate = new Date(bYear, bMonth, bDay);

    return isAsc
      ? aDate.getTime() - bDate.getTime()
      : bDate.getTime() - aDate.getTime();
  }
};

export default groupBaggingReportsById;

import React, {Component} from 'react';
import { faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Th extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      direction: 'asc'
    };

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    let { direction } = this.state;
    let { col, onClick } = this.props;
    let nextDirection = direction === 'asc' ? 'desc' : 'asc';
    
    if (onClick)
      onClick({
        col,
        direction
      });
    
    this.setState({...this.state, direction: nextDirection});
  }
  
  render() {
    return (
      <th className={'is-relative pointer th'} onClick={this.handleClick}>
        {this.props.children}
        <FontAwesomeIcon
          className={(this.props.isSelected ? 'selected' : '') +' th-icon'} 
          icon={this.state.direction === 'asc' ? faSortUp : faSortDown} 
          size={'xs'} />
      </th>
    );
  }
}

export default Th;

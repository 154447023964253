import React, { useState, useEffect } from "react";
import UserDataTable from "../user-data-table";
import SearchInput from "../search-input";
import { fetchUsers, fetchUserDetails } from "../../utils/api";
import { useAuth0 } from "../../auth0-spa";
import { debounce } from "lodash";
import Spinner from "../spinner";
import userTableSettings from "../../utils/userTableSettings";
import { useHistory } from "react-router-dom";
import { filter, sort } from "../../utils/array";

const Users = (props) => {
  let history = useHistory();
  let { loading, getTokenSilently } = useAuth0();
  let [users, setUsers] = useState([]);
  let [userCompany, setUserCompany] = useState([]);
  let [filterBy, setFilterBy] = useState("");
  let [isFetching, setFetching] = useState(false);
  let [sortBy, setSortBy] = useState("");
  let [sortDirection, setSortDirection] = useState("asc");
  let [colSelected, setColSelected] = useState(null);
  let [searchedTblData, setSearchedTblData] = useState([]);

  const onSearch = (e) => {
    setFilterBy(e.target.value);
  };

  const debouncedOnSearch = debounce(onSearch, 100);

  const thClicked = (column) => {
    setSortBy(column.col);
    setSortDirection(column.direction);
    setColSelected(column.col);
    setSearchedTblData(
      users
        .filter((row) => {
          return filter({ row, filterBy });
        })
        .sort((a, b) => {
          return sort({ a, b, sortBy, sortDirection, table: "users" });
        })
    );
  };

  useEffect(() => {
    if (users) {
      setSearchedTblData(
        users
          .filter((row) => {
            return filter({ row, filterBy });
          })
          .sort((a, b) => {
            return sort({ a, b, sortBy, sortDirection, table: "users" });
          })
      );
    }
  }, [filterBy, users, sortBy, sortDirection]);

  useEffect(() => {
    const callApi = async () => {
      let token = await getTokenSilently();
      try {
        let res = await fetchUsers({
          token,
        });
        let formattedRes = [];
        res.data.results.forEach((result) => {
          formattedRes.push(result.user);
        });
        setUsers(formattedRes);
      } catch (e) {
        alert(e.message);
        console.error(e.message);
      }
    };
    if (!loading) {
      setFetching(true);
      callApi().finally(() => setFetching(false));
    }
  }, [loading, getTokenSilently]);

  if (loading || isFetching) {
    return <Spinner />;
  }

  const viewDetails = async (data) => {
    let token = await getTokenSilently();
    let res = await fetchUserDetails({
      token,
      pkid: data.row.pkid,
    });
    let formattedCompanies = [];
    res.data.companies.forEach((company) => {
      formattedCompanies.push(company.company_name);
    });
    data.row.Companies = formattedCompanies.join(", ");
    let companyArr = res.data.companies;
    data.row.CompaniesArr = companyArr;
    setUserCompany(formattedCompanies);
    setSearchedTblData(formattedCompanies);
    history.push({
      pathname: `/user/${data.row.pkid}`,
      state: {
        data,
        formattedCompanies,
        companyArr,
      },
    });
  };

  return (
    <div className={"box is-relative"}>
      <div className={"table-search"}>
        <SearchInput onSearch={debouncedOnSearch} />
      </div>
      <UserDataTable
        tblData={searchedTblData}
        settings={userTableSettings}
        fontSize={""}
        onView={viewDetails}
        onThClick={thClicked}
      />
    </div>
  );
};

export default Users;

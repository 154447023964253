export default function genFakeData(nRows = 0, fromJSON = false) {
  //const { filterBy, sortBy } = constraints;
  const jsonData = JSON.parse(`[
  {
    "id": 45894,
    "report_date": "2019-10-16",
    "test_date": "2019-12-01",
    "bagging_report_id": "BBP19-LAT-1",
    "contract_number": "MSL27682",
    "related_record": 22175,
    "related_production_update": null,
    "customer_id": "Latham Hi-Tech Hybrids",
    "notes": "MSL27683",
    "location": "BP",
    "activity": "Bagging",
    "customer_reference_number": "MSL27683",
    "crop": "Seed Corn",
    "package_weight_ssu": "53.5000",
    "packages": "4.0000",
    "variety": "LH 4657 VT2 PRO RIB",
    "screen_size": "24/20 R",
    "plate_size": "B1 - C1X",
    "size": "",
    "batch_number": "90BP514RHBA",
    "kernel_count": 4000000,
    "seed_lb": 1505,
    "quantity_to_ship": 200,
    "shipped_uom": "80M",
    "unit_weight": "2675.0000",
    "weight": "1213.0000",
    "crop_year": "2019",
    "relative_maturity": "96",
    "origin": "IA",
    "label_germ": 95,
    "tag_code": "F,AL,L,P2",
    "bag_lip_code": "A250",
    "treatment": "Acceleron 250  Basic - 2G",
    "product_variety": "LH 4657 VT2 PRO RIB",
    "package": "LAT : Treated Bulk Box :  : Corn",
    "colorant": "Green",
    "product_code": "NN4687DNHZ",
    "refuge_origin": "IA",
    "refuge_label_germ": 95,
    "refuge_test_date": "2019-12-01T00:00:00Z",
    "refuge_product_code": "NJ4761HTTZ",
    "refuge_trait": "RR",
    "refuge_batch_number": "BP8A2022BC",
    "refuge_seed_lb": 1388,
    "refuge_tag_code": "F,AL,L,P2",
    "refuge_treatment": "Acceleron 250  Basic - 2G",
    "refuge_colorant": "Purple",
    "finished_type": "Blended",
    "package_type": "Hard Box",
    "ridQB": "87261",
    "migratedQB": true,
    "display": true,
    "downloadable": false
  },
  {
    "id": 45891,
    "report_date": "2019-10-16",
    "test_date": "2019-12-01",
    "bagging_report_id": "BBP19-LAT-2",
    "contract_number": "MSL27682",
    "related_record": 22175,
    "related_production_update": null,
    "customer_id": "Latham Hi-Tech Hybrids",
    "notes": "MSL27683",
    "location": "BP",
    "activity": "Bagging",
    "customer_reference_number": "MSL27683",
    "crop": "Seed Corn",
    "package_weight_ssu": "44.5000",
    "packages": "100.0000",
    "variety": "LH 4657 VT2 PRO RIB",
    "screen_size": "20/16 R",
    "plate_size": "",
    "size": "14 x 3 x 33",
    "batch_number": "90BP511RA",
    "kernel_count": 80000,
    "seed_lb": 1804,
    "quantity_to_ship": 100,
    "shipped_uom": "80M",
    "unit_weight": "45.0000",
    "weight": "20.0000",
    "crop_year": "2019",
    "relative_maturity": "96",
    "origin": "IA",
    "label_germ": 95,
    "tag_code": "F,AL,L,P2",
    "bag_lip_code": "A250",
    "treatment": "Acceleron 250  Basic - 2G",
    "product_variety": "LH 4657 VT2 PRO RIB",
    "package": "LAT : RIB Small : 14 x 3 x 33 : Corn",
    "colorant": "Green",
    "product_code": "NN4687DNHZ",
    "refuge_origin": "IA",
    "refuge_label_germ": 95,
    "refuge_test_date": "2019-12-01T00:00:00Z",
    "refuge_product_code": "NJ4761HTTZ",
    "refuge_trait": "RR",
    "refuge_batch_number": "BP8A2020BC",
    "refuge_seed_lb": 1730,
    "refuge_tag_code": "F,AL,L,P2",
    "refuge_treatment": "Acceleron 250  Basic - 2G",
    "refuge_colorant": "Purple",
    "finished_type": "Blended",
    "package_type": "Branded Bag",
    "ridQB": "87258",
    "migratedQB": true,
    "display": true,
    "downloadable": false
  },
  {
    "id": 45892,
    "report_date": "2019-10-16",
    "test_date": "2019-12-01",
    "bagging_report_id": "BBP19-LAT-2",
    "contract_number": "MSL27682",
    "related_record": 22175,
    "related_production_update": null,
    "customer_id": "Latham Hi-Tech Hybrids",
    "notes": "MSL27683",
    "location": "BP",
    "activity": "Bagging",
    "customer_reference_number": "MSL27683",
    "crop": "Seed Corn",
    "package_weight_ssu": "44.5000",
    "packages": "5.0000",
    "variety": "LH 4657 VT2 PRO RIB",
    "screen_size": "20/16 R",
    "plate_size": "",
    "size": "",
    "batch_number": "90BP510RHBA",
    "kernel_count": 4000000,
    "seed_lb": 1804,
    "quantity_to_ship": 250,
    "shipped_uom": "80M",
    "unit_weight": "2225.0000",
    "weight": "1009.0000",
    "crop_year": "2019",
    "relative_maturity": "96",
    "origin": "IA",
    "label_germ": 95,
    "tag_code": "F,AL,L,P2",
    "bag_lip_code": "A250",
    "treatment": "Acceleron 250  Basic - 2G",
    "product_variety": "LH 4657 VT2 PRO RIB",
    "package": "LAT : Treated Bulk Box :  : Corn",
    "colorant": "Green",
    "product_code": "NN4687DNHZ",
    "refuge_origin": "IA",
    "refuge_label_germ": 95,
    "refuge_test_date": "2019-12-01T00:00:00Z",
    "refuge_product_code": "NJ4761HTTZ",
    "refuge_trait": "RR",
    "refuge_batch_number": "BP8A2020BC",
    "refuge_seed_lb": 1730,
    "refuge_tag_code": "F,AL,L,P2",
    "refuge_treatment": "Acceleron 250  Basic - 2G",
    "refuge_colorant": "Purple",
    "finished_type": "Blended",
    "package_type": "Hard Box",
    "ridQB": "87259",
    "migratedQB": true,
    "display": true,
    "downloadable": false
  },
  {
    "id": 45890,
    "report_date": "2019-10-16",
    "test_date": "2019-12-01",
    "bagging_report_id": "BBP19-LAT-1",
    "contract_number": "MSL27683",
    "related_record": 22175,
    "related_production_update": null,
    "customer_id": "Latham Hi-Tech Hybrids",
    "notes": "MSL27683",
    "location": "BP",
    "activity": "Bagging",
    "customer_reference_number": "MSL27683",
    "crop": "Seed Corn",
    "package_weight_ssu": "39.5000",
    "packages": "150.0000",
    "variety": "LH 4657 VT2 PRO RIB",
    "screen_size": "20/16 F",
    "plate_size": "B9 - C9",
    "size": "14 x 3 x 33",
    "batch_number": "90BP512RA",
    "kernel_count": 80000,
    "seed_lb": 2056,
    "quantity_to_ship": 150,
    "shipped_uom": "80M",
    "unit_weight": "40.0000",
    "weight": "18.0000",
    "crop_year": "2019",
    "relative_maturity": "96",
    "origin": "IA",
    "label_germ": 95,
    "tag_code": "F,AL,L,P2",
    "bag_lip_code": "A250",
    "treatment": "Acceleron 250  Basic - 2G",
    "product_variety": "LH 4657 VT2 PRO RIB",
    "package": "LAT : RIB Small : 14 x 3 x 33 : Corn",
    "colorant": "Green",
    "product_code": "NN4687DNHZ",
    "refuge_origin": "IA",
    "refuge_label_germ": 95,
    "refuge_test_date": "2019-12-01T00:00:00Z",
    "refuge_product_code": "NJ4761HTTZ",
    "refuge_trait": "RR",
    "refuge_batch_number": "BP8A2020BC",
    "refuge_seed_lb": 1730,
    "refuge_tag_code": "F,AL,L,P2",
    "refuge_treatment": "Acceleron 250  Basic - 2G",
    "refuge_colorant": "Purple",
    "finished_type": "Blended",
    "package_type": "Branded Bag",
    "ridQB": "87257",
    "migratedQB": true,
    "display": true,
    "downloadable": false
  },
  {
    "id": 45895,
    "report_date": "2019-10-16",
    "test_date": "2019-12-01",
    "bagging_report_id": "BBP19-LAT-1",
    "contract_number": "MSL27683",
    "related_record": 22175,
    "related_production_update": null,
    "customer_id": "Latham Hi-Tech Hybrids",
    "notes": "MSL27683",
    "location": "BP",
    "activity": "Bagging",
    "customer_reference_number": "MSL27683",
    "crop": "Seed Corn",
    "package_weight_ssu": "53.5000",
    "packages": "150.0000",
    "variety": "LH 4657 VT2 PRO RIB",
    "screen_size": "24/20 R",
    "plate_size": "B1 - C1X",
    "size": "14 x 4 x 34",
    "batch_number": "90BP515RA",
    "kernel_count": 80000,
    "seed_lb": 1505,
    "quantity_to_ship": 150,
    "shipped_uom": "80M",
    "unit_weight": "54.0000",
    "weight": "24.0000",
    "crop_year": "2019",
    "relative_maturity": "96",
    "origin": "IA",
    "label_germ": 95,
    "tag_code": "F,AL,L,P2",
    "bag_lip_code": "A250",
    "treatment": "Acceleron 250  Basic - 2G",
    "product_variety": "LH 4657 VT2 PRO RIB",
    "package": "LAT : RIB Med : 14 x 4 x 34 : Corn",
    "colorant": "Green",
    "product_code": "NN4687DNHZ",
    "refuge_origin": "IA",
    "refuge_label_germ": 95,
    "refuge_test_date": "2019-12-01T00:00:00Z",
    "refuge_product_code": "NJ4761HTTZ",
    "refuge_trait": "RR",
    "refuge_batch_number": "BP8A2022BC",
    "refuge_seed_lb": 1388,
    "refuge_tag_code": "F,AL,L,P2",
    "refuge_treatment": "Acceleron 250  Basic - 2G",
    "refuge_colorant": "Purple",
    "finished_type": "Blended",
    "package_type": "Branded Bag",
    "ridQB": "87262",
    "migratedQB": true,
    "display": true,
    "downloadable": false
  },
  {
    "id": 45893,
    "report_date": "2019-10-16",
    "test_date": "2019-12-01",
    "bagging_report_id": "BBP19-LAT-1",
    "contract_number": "MSL27683",
    "related_record": 22175,
    "related_production_update": null,
    "customer_id": "Latham Hi-Tech Hybrids",
    "notes": "MSL27683",
    "location": "BP",
    "activity": "Bagging",
    "customer_reference_number": "MSL27683",
    "crop": "Seed Corn",
    "package_weight_ssu": "46.5000",
    "packages": "3.0000",
    "variety": "LH 4657 VT2 PRO RIB",
    "screen_size": "24/20 F",
    "plate_size": "B7 - C7",
    "size": "",
    "batch_number": "90BP513RHBA",
    "kernel_count": 4000000,
    "seed_lb": 1736,
    "quantity_to_ship": 150,
    "shipped_uom": "80M",
    "unit_weight": "2325.0000",
    "weight": "1055.0000",
    "crop_year": "2019",
    "relative_maturity": "96",
    "origin": "IA",
    "label_germ": 95,
    "tag_code": "F,AL,L,P2",
    "bag_lip_code": "A250",
    "treatment": "Acceleron 250  Basic - 2G",
    "product_variety": "LH 4657 VT2 PRO RIB",
    "package": "LAT : Treated Bulk Box :  : Corn",
    "colorant": "Green",
    "product_code": "NN4687DNHZ",
    "refuge_origin": "IA",
    "refuge_label_germ": 95,
    "refuge_test_date": "2019-12-01T00:00:00Z",
    "refuge_product_code": "NJ4761HTTZ",
    "refuge_trait": "RR",
    "refuge_batch_number": "BP8A2022BC",
    "refuge_seed_lb": 1388,
    "refuge_tag_code": "F,AL,L,P2",
    "refuge_treatment": "Acceleron 250  Basic - 2G",
    "refuge_colorant": "Purple",
    "finished_type": "Blended",
    "package_type": "Hard Box",
    "ridQB": "87260",
    "migratedQB": true,
    "display": true,
    "downloadable": false
  }
]`);
  const fakeData = [];
  const model = {
    bagging_report_id: String,
    bagging_report_date: Date,
    contract_number: String,
    related_record: Number,
    related_production_update: Number,
    customer_id: String,
    notes: String,
    location: String,
    activity: String,
    customer_reference_number: String,
    crop: String,
    package_weight_ssu: Number,
    packages: Number,
    variety: String,
    screen_size: String,
    plate_size: String,
    size: String,
    batch_number: String,
    kernel_count: Number,
    seed_lb: Number,
    unit_weight: Number,
    weight: Number,
    crop_year: String,
    relative_maturity: String,
    test_date: Date,
    origin: String,
    label_germ: Number,
    tag_code: String,
    bag_lip_code: String,
    treatment: String,
    product_variety: String,
    package: String,
    colorant: String,
    product_code: String,
    refuge_origin: String,
    refuge_label_germ: Number,
    refuge_test_date: Date,
    refuge_product_code: String,
    refuge_trait: String,
    refuge_batch_number: String,
    refuge_seed_lb: Number,
    refuge_tag_code: String,
    refuge_treatment: String,
    refuge_colorant: String,
    finished_type: String,
    package_type: String,
    ridQB: String,
    migratedQB: Boolean,
    display: Boolean,
  };
  
  if (fromJSON) {
    return Promise.resolve(jsonData);
  }
  
  while(nRows) {
    let row = {};
    for (let col in model) {
      row[col] = getRandomData(model[col]);
    }
    fakeData.push(row);
    nRows--;
  }
  
  return Promise.resolve({
    //data: fakeData.filter(filter).sort(sort)
    getData,
    
  });
  
  function getData(filterBy, sortBy) {
    return Promise.resolve(fakeData.filter(filter).sort(sort));
    
    function filter(row) {
      if (!filterBy) return true;

      let search = new RegExp(filterBy);

      let searchableData = Object.values(row);

      return searchableData.some(td => search.test(td));
    }

    function sort(a, b) {
      if (!sortBy || !model[sortBy]) return 0;

      if (model[sortBy] === Number) {
        return a[sortBy] - b[sortBy];
      }

      if (model[sortBy] === String) {
        return a[sortBy].localeCompare(b[sortBy]);
      }

      if (model[sortBy] === Date) {
        let [month, day, year ] = a[sortBy].split('/');
        let [bMonth, bDay, bYear] = b[sortBy].split('/');
        let date = new Date(year, month, day);
        let bDate = new Date(bYear, bMonth, bDay);

        return date.getTime() - bDate.getTime();
      }
    } 
  }
  
  function getRandomData(type) {
    switch (type) {
      case Boolean:
        return genRandomBoolean();
      case String:
        return genRandomString();
      case Date:
        return genRandomDate();
      case Number:
        return genRandomNumber();
      default:
        return genRandomString();
    }
  }
  
  function genRandomString() {
    return Math.random().toString(36).substr(2);
  }
  
  function genRandomDate() {
    return new Date(Math.random()*(1000*86400*365*100)).toLocaleDateString();
  }
  
  function genRandomBoolean() {
    const bools = [true, false];
    return bools[Math.floor(Math.random()*bools.length)]
  }
  
  function genRandomNumber() {
    return Math.floor(Math.random()*10000);
  }
}

import React from 'react';
import Td from './td';
import Th from './th';
import RowActions from '../row-actions';

const DataTable = (props) => {
    let { formats, headers }  = props.settings;
    let { fontSize, colSelected, onThClick, onTdClick, tblData, approveBaggingReport, disapproveBaggingReport } = props;
    let fontSizeClasses = {
      'small': 'is-size-7',
      'medium': '',
      'large': 'is-size-5'
    };
    let fontSizeClass = fontSizeClasses[fontSize] || '';
    
    return (
      <div className="table-container">
        <table className={`table is-striped is-hoverable is-fullwidth ${fontSizeClass}`}>
          <thead>
          <tr>
            {headers
              .map((header, i) => (
                <Th key={`datatable-head-${i}`} 
                    col={header.colName} 
                    isSelected={colSelected === header.colName} onClick={onThClick}>
                  {header.title}
                </Th>)
              )}
              <th>Upload Status</th>
          </tr>
          </thead>
          <tbody>
          {tblData.map((row, i) => (
            <tr key={`datatable-row-${i}`}>
              {headers.map((header, i) => (
                <Td key={`datatable-td-${i}`} 
                    val={formats[header.colName] ? 
                      formats[header.colName](row[header.colName]) : 
                      row[header.colName]}
                    row={row} 
                    onClick={onTdClick} />
              ))}
              <td className={'vertical-middle'}>
                <RowActions record={row} onApprove={approveBaggingReport} onDisapprove={disapproveBaggingReport}/>
              </td>
            </tr>
          ))}
          </tbody>
        </table>
      </div>
    );
};

export default DataTable;

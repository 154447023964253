import React from 'react';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faSortAmountDown, faSortAmountUp } from '@fortawesome/free-solid-svg-icons';

const SearchInput = ({ label, transparentBG, onSearch, /*onSort, hasDateSortDropDown, defaultSort*/ }) => {
  //let [ sort, setSort ] = useState(defaultSort || 'asc');

  /*useEffect(() => {
    if (onSort) onSort(sort);
  }, [sort, onSort])*/
  
  let handleSearch = (e) => {
    e.persist();
    if (onSearch) {
      onSearch(e);
    }
  };
  
  return (
    <div className={`field`}> {/*${hasDateSortDropDown ? 'is-grouped' : ''}*/}
      {label ? <label className="label">{label}</label> : ''}

      {/*
        hasDateSortDropDown &&
        <div className="control has-icons-left">
          <span className="icon is-left" style={{zIndex: 5}}>
            <FontAwesomeIcon icon={sort === 'asc' ? faSortAmountUp : faSortAmountDown}/>
          </span>

          <div className="select is-success">
            <select title="Sort By Report Date"
                    className={`${transparentBG ? 'has-background-white-ter' : ''}`}
                    onChange={(e) => setSort(e.target.value)}
                    value={sort}>
              <option value="asc">Asc.</option>
              <option value="desc">Desc.</option>
            </select>
          </div>
        </div>*/
      }

      <p className="control has-icons-left is-expanded">
        <input className={`input is-success ${transparentBG ? 'has-background-white-ter' : ''}`}
               onChange={handleSearch}
               type="search"
               placeholder="Search" />
        <span className="icon is-small is-left">
          <FontAwesomeIcon icon={faSearch} />
        </span>
      </p>
    </div>
  );
};

export default SearchInput;

import React from 'react';

const NoData = (props) => {
  return (<div className={'columns is-centered'}>
    <div className="column is-6">
      <h5 className="title is-5 has-text-centered">{props.message}</h5>
      <img src='/img/empty.svg' alt={'No Bagging Reports Or Contracts To Show'}/>
    </div>
  </div>)
};

export default NoData;

import React, { useState, useEffect } from 'react';
import * as Sentry from '@sentry/browser';
import baggingReportsTableSettings from '../../utils/baggingReportsTableSettings';
import { useAuth0 } from '../../auth0-spa';
import {
  fetchBaggingReports,
  editBaggingReports,
  editBaggingReport
} from '../../utils/api';
import { groupBaggingReportsById, getBaggingReportLocations, getBaggingReportDates } from '../../utils/array';
import Spinner from '../spinner';
import BaggingReportsTable from '../bagging-reports-table';
import { Link } from 'react-router-dom';
import BaggingReportSummary from '../bagging-report-summary';

const BaggingReportsTables = (props) => {
  let { loading, getTokenSilently, user } = useAuth0();
  let { state = {} } = props.location;
  let { reports } = state;
  let tablesFromState = groupBaggingReportsById(reports || []);
  let contractNumber = props.match.params.id;
  let approvalStatuses = (reports || [])
    .map(report => report.downloadable);
  let [isFetching, setFetching] = useState(false);
  let [isApproving, setIsApproving] = useState(false);
  let [tables, setTables] = useState(tablesFromState);
  let [tableKeys, setTableKeys] = useState(tables.map(table => table.reports.map(report => report.downloadable).toString()));
  let [locations, setLocations] = useState(getBaggingReportLocations(reports || []));
  let [dateString, setDateString] = useState(getBaggingReportDates(reports || []));
  let [canApprove, setCanApprove] = useState(approvalStatuses.some(status => !status));

  const updateReport = async (config) => {
    let { fromFake, token, edits, recordId, bagging_report_id } = config;

    try {
      let res = await editBaggingReport({
        fromFake,
        token,
        edits,
        recordId,
        email: user.email,
        companyId: ''
      });
      
      setTables(tables.map(table => {
        if (table.reportId === bagging_report_id) {
          table.reports = table.reports.map(baggingReport => {
            if (baggingReport.id === recordId) {
              for (let field in edits) {
                baggingReport[field] = edits[field];
              }
            }

            return baggingReport;
          });
        }
        return table;
      }));
      return Promise.resolve(res);
    } catch(e) {
      alert(e.message);
      console.error(e);
      Sentry.captureException(e);
      return Promise.reject(e);
    }
  };
  const onApproveBaggingReports = async () => {
    setIsApproving(true);
    let fromFake = false;
    let token = await getTokenSilently();
    let reports = (tables || [])
      .reduce((p,c) => p.concat(c.reports), [])
      .filter(report => !report.downloadable);
    let edits = {
      downloadable: true
    };

    try {
      let res = await editBaggingReports({token, reports, edits, fromFake, email: user.email, companyId: 'LAT'});
      let updatedRecords = res.map(response => response.data);
      let updatedTables = tables.map(table => {
        table.reports = table.reports.map(report => {
          let updatedReport = updatedRecords.find(record => record.id === report.id);
          
          if (updatedReport) return updatedReport;
          
          return report;
        });
        
        return table;
      });
      
      setTables(updatedTables);
      setCanApprove(false);
      setIsApproving(false);
    } catch (e) {
      let { response = {}} = e;
      let { status } = response;

      alert(e.message);
      setIsApproving(false);

      if (status && status >= 500) {
        console.error(e.message);
        Sentry.captureException(e);
      }
    }
  };
  const onDisapproveBaggingReport = async (report) => {
    setIsApproving(true);
    let fromFake = false;
    let token = await getTokenSilently();
    let edits = {
      downloadable: false
    };

    await updateReport({
      fromFake,
      token,
      edits,
      recordId: report.id,
      bagging_report_id: report.report_id,
    });
    
    setIsApproving(false);
  };
  const onApproveBaggingReport = async (report) => {
    setIsApproving(true);
    let fromFake = false;
    let token = await getTokenSilently();
    let edits = {
      downloadable: true
    };

    await updateReport({
      fromFake,
      token,
      edits,
      recordId: report.id,
      bagging_report_id: report.report_id,
    });

    setIsApproving(false);
  };
  
  useEffect(() => {
    setTableKeys(
      tables.map(table => table.reports.map(report => report.downloadable).toString())
    );
    setCanApprove(
      tables
      .map(tbl => tbl.reports)
      .reduce((p,c) => p.concat(c), [])
      .some(report => !report.downloadable)
    );
  }, [tables]);
  
  useEffect(() => {
    const callApi = async () => {
      let token = await getTokenSilently();

      try {
        let res = await fetchBaggingReports({
          token, 
          email: user.email, 
          contract_number: contractNumber,
        });
        
        let subTables = groupBaggingReportsById(res.data);
        setTables(subTables);
        setLocations(getBaggingReportLocations(res.data));
        setDateString(getBaggingReportDates(res.data));
        setCanApprove(res.data.some(report => !report.downloadable));
      } catch(e) {
        alert(e.message);
        console.error(e.message);
        Sentry.captureException(e);
      }
    };

    if (!loading && !reports) {
      setFetching(true);
      callApi().finally(() => setFetching(false));
    }
  }, [loading, getTokenSilently, contractNumber, user, reports]);

  if (loading || isFetching) {
    return <Spinner />;
  }

  return (
    <div>
      <nav className="breadcrumb">
        <ul>
          <li><Link to="/">Bagging Reports</Link></li>
          <li className={'is-active'}><a href="/#">{contractNumber}</a></li>
        </ul>
      </nav>
      <div className="columns">
        <div className="column is-3">
          <BaggingReportSummary canApprove={canApprove} 
                                contractNumber={contractNumber} 
                                tables={tables} 
                                dateString={dateString} 
                                locations={locations} 
                                onApproveBaggingReports={onApproveBaggingReports} 
                                isApproving={isApproving} />
        </div>
        <div className="column">
          {tables.map((table, i) => (<BaggingReportsTable tblSettings={baggingReportsTableSettings} 
                                                          fullyApproved={!canApprove} 
                                                          approveBaggingReport={onApproveBaggingReport} 
                                                          disapproveBaggingReport={onDisapproveBaggingReport} 
                                                          reportId={table.reportId} 
                                                          reports={table.reports}
                                                          reportsInState={reports}
                                                          fontSize={''} 
                                                          key={`bagging-reports-table-${i}-${tableKeys[i]}`} />))}
        </div>
      </div>
    </div>
  );
};

export default BaggingReportsTables;

export const isLocalEnv = process.env.NODE_ENV === 'development';
export const urlBase = isLocalEnv ? '' : 
  'https://mayerseedline.seedbin.com';

const __ENV__ = {
  isLocalEnv,
  urlBase,
}

export default __ENV__;

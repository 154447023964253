import React, { useState, useEffect } from "react";
import DataTable from "../data-table";
import SearchInput from "../search-input";
import { debounce } from "lodash";
import { filter, sort } from "../../utils/array";
import { useHistory } from "react-router-dom";

const BaggingReportsTable = (props) => {
  let {
    reports,
    reportId,
    fontSize,
    approveBaggingReport,
    disapproveBaggingReport,
    fullyApproved,
    tblSettings,
    reportsInState,
  } = props;
  let history = useHistory();
  let [searchedTblData, setSearchedTblData] = useState([]);
  let [filterBy, setFilterBy] = useState("");
  let [sortBy, setSortBy] = useState("");
  let [totalUnits, setTotalUnits] = useState(0);
  let [sortDirection, setSortDirection] = useState("asc");
  let [colSelected, setColSelected] = useState(null);

  const onSearch = (e) => {
    setFilterBy(e.target.value);
  };

  const debouncedOnSearch = debounce(onSearch, 100);

  const tdClicked = (data) => {
    history.push({
      pathname: `/report/${data.row.id}`,
      state: {
        reportsInState,
      },
    });
  };

  const thClicked = (column) => {
    setSortBy(column.col);
    setSortDirection(column.direction);
    setColSelected(column.col);
    // not putting this in useEffect b/c depends on multiple state changes
    setSearchedTblData(
      reports
        .filter((row) => {
          return filter({ row, filterBy });
        })
        .sort((a, b) => {
          return sort({ a, b, sortBy, sortDirection, table: "baggingReports" });
        })
    );
  };

  useEffect(() => {
    if (reports) {
      setSearchedTblData(
        reports
          .filter((row) => {
            return filter({ row, filterBy });
          })
          .sort((a, b) => {
            return sort({
              a,
              b,
              sortBy,
              sortDirection,
              table: "baggingReports",
            });
          })
      );
      let newReports = reports
        .filter((row) => {
          return filter({ row, filterBy });
        })
        .sort((a, b) => {
          return sort({
            a,
            b,
            sortBy,
            sortDirection,
            table: "baggingReports",
          });
        });
      let total = 0;
      newReports.forEach((report) => {
        total += report.quantity_to_ship;
      });
      setTotalUnits(total);
    }
  }, [filterBy, reports, sortBy, sortDirection]);

  return (
    <div className={"box is-relative"}>
      <div className={"table-search"}>
        <div>
          <span
            className={`tag is-light is-size-5 ${
              fullyApproved ? "is-success" : "is-light"
            }`}
          >
            {reportId}
          </span>
        </div>
        <SearchInput onSearch={debouncedOnSearch} />
      </div>
      <DataTable
        tblData={searchedTblData}
        settings={tblSettings}
        onTdClick={tdClicked}
        colSelected={colSelected}
        onThClick={thClicked}
        fontSize={fontSize}
        approveBaggingReport={approveBaggingReport}
        disapproveBaggingReport={disapproveBaggingReport}
      />
      <div className="table-container">
        <div style={{ textAlign: "right", width: "73%" }}>
          <b>Total 80M Units:</b> {totalUnits}
        </div>
      </div>
    </div>
  );
};

export default BaggingReportsTable;

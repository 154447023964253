import React, { useState, useEffect } from 'react';
import * as Sentry from '@sentry/browser';
import BaggingReportFields from './bagging-report-fields';
import Spinner from '../spinner';
import { useAuth0 } from '../../auth0-spa';
import { fetchBaggingReport } from '../../utils/api';
import { Link } from 'react-router-dom';

const BaggingReport = ({ location, match }) => {
  let [ baggingReport, setBaggingReport ] = useState({});
  let [ isFetching, setFetching ] = useState(false);
  let { loading, isAuthenticated, loginWithRedirect, getTokenSilently, user } = useAuth0();

  useEffect(() => {
    let controller = new AbortController();
    let { signal } = controller;
    
    const getBaggingReport = async () => {
      let token = await getTokenSilently();
      let id = match.params.id;

      try {
        let res = await fetchBaggingReport({token, id, email: user.email, signal});
        setBaggingReport(res.data);
      } catch(e) {
        let { response = {}} = e;
        let { status } = response;
        
        alert(e.message);
        
        if (status && status >= 500) {
          console.error(e.message);
          Sentry.captureException(e);
        }
      }
    };

    if (!loading) {
      setFetching(true);
      getBaggingReport()
        .finally(() => setFetching(false));
    }
    
    return () => controller.abort();
    
  }, [loading, getTokenSilently, user, match.params.id]);

  if (loading) {
    return <Spinner />;
  }

  if (!isAuthenticated) {
    loginWithRedirect();
    return null;
  }
    
  return (
    <div>
      <nav className="breadcrumb">
        <ul>
          <li><Link to="/">Bagging Reports</Link></li>
          <li><Link to={{
            pathname: `/contract/${baggingReport.contract_number}`,
            state: location.state
          }}>{baggingReport.contract_number}</Link></li>
          <li  className={'is-active'}><a href="/#">Line Item #: {baggingReport.id}</a></li>
        </ul>
      </nav>
      <div className="box is-relative min-h-250">
        <h1 className="title has-text-primary has-text-weight-bold">
          {baggingReport.bagging_report_id || ''}
        </h1>
        {isFetching ? 
          <Spinner /> :
          <BaggingReportFields baggingReport={baggingReport} />
        }
      </div>
    </div>
  );
};

export default BaggingReport;

import React, { useState, useEffect } from 'react';
import * as Sentry from '@sentry/browser';
import Nav from './components/nav';
import Footer from './components/footer';
import Content from './components/content';
import BaggingReportsTables from './components/bagging-reports-tables';
import BaggingReport from './components/bagging-report';
import ScrollToTop from './components/scroll-to-top';
import Contracts from './components/contracts';
import LogUserIn from './components/log-user-in';
import AppMenu from './components/app-menu';
import Spinner from './components/spinner';
import Users from './components/users';
import UserDetails from './components/user-details';
import { useAuth0 } from './auth0-spa';
import history from './utils/history';
import { fetchUserCompanies } from './utils/api';
import {
  HashRouter as Router,
  Switch,
  Route,
  NavLink
} from 'react-router-dom';

const App = () => {
  let { isAuthenticated, getTokenSilently, loading, loginWithRedirect, user, logout } = useAuth0();
  let [ currentRoute, setCurrentRoute ] = useState(history.location.pathname);
  let [ hideSideBar, setHideSideBar ] = useState(false);
  let [ companies, setCompanies ] = useState([ ]);
  let [ selectedCompany, setSelectedCompany ] = useState('');
  let [ active, setActive ] = useState(null);
  let [ isActive, setActiveFlag ] = useState(false);

  history.listen((location) => {
    setCurrentRoute(location.pathname);
  });

  useEffect(() => {
    if (user) {
      Sentry.configureScope(function(scope) {
        scope.setUser({'email': user.email});
      });
    }
  }, [user]);

  useEffect(() => {
    const callApi = async () => {
      let token = await getTokenSilently();

      try {
        let res = await fetchUserCompanies({token, email: user.email});
        let companies = [];
        res.data.seedbin_user.company.forEach((c) => {
          companies.push(c);
        })
        setCompanies(companies);
      } catch(e) {
        alert(e.message);
        console.error(e.message);
      }
    };
    if (!loading ) {
      callApi();
    }
  }, [loading, getTokenSilently, user]);

  const companyClicked = (e) => {
    e.preventDefault();
    
    if (isActive && selectedCompany === e.currentTarget.text) {
      setSelectedCompany('');
      setActive('');
      setActiveFlag(!isActive);
    } else if (isActive && e.currentTarget.name !== "") {
      if (e.currentTarget.text === 'Companies') {
        setSelectedCompany('');
        setActive(e.currentTarget.name);
      } else {
        setSelectedCompany(e.currentTarget.text);
        setActive(e.currentTarget.name);
      }
    } else {
      if (e.currentTarget.text === 'Companies') {
        setSelectedCompany('');
      } else {
        setSelectedCompany(e.currentTarget.text);
      }
      setActive(e.currentTarget.name);
      setActiveFlag(!isActive);
    }
  };
  
  useEffect(() => {
    let pagesWithBreadcrumb = [
      'contract',
      'report',
    ];
    let currentPage = currentRoute.split('/')[1];
    setHideSideBar(pagesWithBreadcrumb.includes(currentPage));
  }, [currentRoute]);
  
  if (loading) {
    return <Spinner />
  }
  
  if (!isAuthenticated) {
    loginWithRedirect();
    return null;
  }
  
  if (!user) {
    return <Spinner />
  }
  
  return (<Router>
    <ScrollToTop />
    {/*MayerSeedline_Logo_WHT*/}
    <div className="App">
      <Nav userName={user.given_name}
           userProfile={user.picture}
           logout={() => logout({returnTo: window.location.origin})}
           logo={'/img/HopperLogoReversed.RGB_revised.png'}
           bigLogo
           title={selectedCompany}
      >
        <NavLink to={'/'} className={'navbar-item'} onClick={companyClicked}>
          Companies
        </NavLink>
        
        <div className="navbar-item dropdown">
          <div className="dropdown-trigger">
            {
              companies.length !== 0 && !loading && 
              companies.map(company => (
                <a 
                  href="/"
                  key={company.company_id} 
                  className={`navbar-item ${active === company.company_id && isActive ? 'is-active' : ''}`}
                  name={company.company_id}
                  onClick={companyClicked}
                >
                  {company.company_name}
                </a>
              ))
            }
            
          </div>
        </div>
      </Nav>

      <div className="container is-fluid">
        <div className="columns">
          <div className={`column is-2 ${hideSideBar ? 'is-hidden' : ''}`}>
            <AppMenu user={user}/>
          </div>
          <div className="column">
            <Content className={hideSideBar ? 'p-half' : 'pl-half'}>
              <Switch>
                <Route path="/" exact > 
                  <Contracts selectedCompany={active} />
                </Route>
                <Route path="/contract/:id" component={BaggingReportsTables} />
                <Route path="/users" component={Users} />
                <Route path="/user/:id" component={UserDetails} />
                <Route path="/login" component={LogUserIn} />
                <Route path="/report/:id" component={BaggingReport} />
              </Switch>
            </Content>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  </Router>);
  };

export default App;

import React, {Component} from 'react';
import {faCog} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


class Spinner extends Component {
  render() {
    return (
      <div className={'is-overlay absolute-center has-text-grey-light'}>
        <FontAwesomeIcon icon={faCog} 
                         size={'7x'} 
                         spin />
      </div>
    );
  }
}

export default Spinner;

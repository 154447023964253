import React from 'react';

const Footer = () => (
      <footer className="footer">
        <div className="container">
          <div className="content has-text-centered">
            <p className="level is-mobile bring-together">
              <span className="level-item">Powered by</span> <a className="level-item" href="https://seedbin.com/">
              <img className="max-h-25" height="25" src="https://seedbin.com/wp-content/uploads/2019/04/cropped-SeedBin_logo_320-9.png" alt="SeedBin Logo"/>
            </a>
            </p>
          </div>
        </div>
    </footer>
);

export default Footer;

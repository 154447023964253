import React, {useState} from 'react';

const RowActions = (props) => {
  let { record = {}, onApprove, onDisapprove } = props;
  let { downloadable } = record;
  let [isRequesting, setIsRequesting] = useState(false);
  let disapproveHook = () => {
    setIsRequesting(true);
    return onDisapprove(record);
  };
  let approveHook = () => {
    setIsRequesting(true);
    return onApprove(record);
  };
  
  return downloadable ? 
    (<button onClick={disapproveHook} className={`button is-small ${isRequesting ? 'is-loading' : ''}`}>
      disapprove
    </button>) :
    (<button onClick={approveHook} className={`button is-small is-primary ${isRequesting ? 'is-loading' : ''}`}>
        approve
      </button>);
};

export default RowActions;

import React, { useState } from 'react';

const UserDropDown = ({userName, userProfile, logout, children}) => {
  let [isActive, setActive] = useState(false);
  
  return (
    <div onMouseLeave={() => setActive(false)} 
         onMouseEnter={() => setActive(true)} 
         onClick={() => setActive(!isActive)} 
         className={`navbar-item has-dropdown h-100 ${isActive ? 'is-active' : ''}`}>
      <span className="navbar-link">
        <img src={userProfile} height={'50'} className={'rounded inline-block mr-md'} alt={userName} />
        <span>{ userName }</span>
      </span>

      <div className="navbar-dropdown is-right">
        {children}
        {children ? <hr className="navbar-divider"/> : ''}
        <a href={'/'} onClick={() => logout()} className="navbar-item">
          Logout
        </a>
      </div>
    </div>
  );
};

export default UserDropDown;
